$wcmc-base: "../";

/*Uncomment the below lines when making a subtheme so that the change of directory
doesn't result in images being lost.
*/

$wcmc-base: "../../../../../../profiles/wcmc/themes/wcm_brand_base/";

// Bring in a couple files from Bootstrap so we can use the clearfix and vendor mixins (for transition, etc)
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

$image-location: $wcmc-base + "images/";

$cornell-red: #B31B1B;

.sp-home{
	width:100%;
}
#banner,
#ctas,
#app,
#donnews,
#last {
  float: none;
  clear: both;
}

#banner{
  display: block;
  width: 100%;
  min-height: 429px;
}

#at-risk {
  
}

.section-inner {
  max-width: 979px;
  margin: 0 auto;
}


